import {
  Header,
  Home,
  Skills,
  Projects,
  Testimonials,
  Contact,
  HireMe,
  Footer,
} from "./components";
function App() {
  return (
    <div className="overflow-hidden">
      <Header />
      <Home />
      <Skills />
      <HireMe />
      <Projects />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
