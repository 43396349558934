import React, { useState } from "react";
import AppWrap from "./wrapper/AppWrap";
import MotionWrap from "./wrapper/MotionWrap";
import homefeedyshome from "../assets/HomeFeedyHome-v2.png";
import procryptoBlack from "../assets/Pro-crypto-black.png";
// import procryptoWhite from "../assets/Pro-crypto-white.png";
import chitchat from "../assets/ChitChat.png";
import writings from "../assets/Writings.png";

const Projects = () => {
  const myProjects = [
    // {
    //   title: "Crypto based Multi features Website",
    //   name: "hashtag cryptoinfo",
    //   purpose:
    //     "The websites idea is to become information hub in the cryptosphere where users can research on projects, read daily news recaps, increase their knowledge of the terms used in crypto industry and more",
    //   image: homefeedyshome,
    //   url: "https://hashtagcryptoinfo.com/",
    // },
    {
      title: "API based Website",
      name: "procrypto",
      purpose:
        "The multipage website fetches latest data for cryptocurrencies, from third party API. It then calculates variances and displays meaningful numbers along with charts.",
      image: procryptoBlack,
      url: "https://procrypto.netlify.app/",
      codeurl: "https://github.com/shubhrapokhariya/procrypto",
    },
    {
      title: "Blog",
      name: "writings",
      purpose:
        "A hobby blog with monetisation options built in, to make it self-dependent or earn profit from it apart from satisfying creative juices.",
      image: writings,
      url: "https://writings-mu.vercel.app/",
      codeurl: "https://github.com/shubhrapokhariya/writings",
    },
    {
      title: "Landing Page",
      name: "home feedy's home",
      purpose:
        "The landing page displays modern design, various animations, features, inspiring adoption of Feedy, a pet dog who has recently lost his family.",
      image: homefeedyshome,
      url: "https://homefeedyshome.netlify.app/",
      codeurl: "https://github.com/shubhrapokhariya/homefeedyshome",
    },
    {
      title: "Social Media App",
      name: "chit chat",
      purpose:
        "The Social Network allows members to create communities on their favourite topics and have engagement around it with other community members.",
      image: chitchat,
      url: "https://chit-chat-dusky.vercel.app/",
      codeurl: "https://github.com/shubhrapokhariya/chit-chat",
    },
  ];

  const tools = [
    // { title: "Crypto based Multi features Website", name: "Nextjs" },
    // { title: "Crypto based Multi features Website", name: "Nodejs" },
    // { title: "Crypto based Multi features Website", name: "MongoDB" },
    // { title: "Crypto based Multi features Website", name: "Mongoose" },
    // { title: "Crypto based Multi features Website", name: "Axios" },
    // { title: "Crypto based Multi features Website", name: "Cheerio" },
    // { title: "Crypto based Multi features Website", name: "Express" },
    // { title: "Crypto based Multi features Website", name: "Jsonwebtoken" },
    // { title: "Crypto based Multi features Website", name: "node-cron" },
    // { title: "Crypto based Multi features Website", name: "SASS" },
    { title: "API based Website", name: "React " },
    { title: "API based Website", name: "Firebase " },
    { title: "API based Website", name: "Axios " },
    { title: "API based Website", name: "Chartjs " },
    { title: "API based Website", name: "Material UI " },
    { title: "Blog", name: "Next.js" },
    { title: "Blog", name: "GraphQL" },
    { title: "Blog", name: "GraphCMS" },
    { title: "Blog", name: "Tailwind CSS" },
    { title: "Landing Page", name: "React" },
    { title: "Landing Page", name: "Tailwind CSS" },
    { title: "Landing Page", name: "Framer Motion" },
    { title: "Social Media App", name: "Next.js" },
    { title: "Social Media App", name: "Typescript" },
    { title: "Social Media App", name: "GraphQL" },
    { title: "Social Media App", name: "Supabase" },
    { title: "Social Media App", name: "StepZen" },
    { title: "Social Media App", name: "Tailwind CSS" },
  ];

  const features = [
    // { title: "Crypto based Multi features Website", name: "Web Scraping" },
    // { title: "Crypto based Multi features Website", name: "Admin Panel" },
    // {
    //   title: "Crypto based Multi features Website",
    //   name: "Advanced and Complex Search",
    // },
    // { title: "Crypto based Multi features Website", name: "Blog" },
    // {
    //   title: "Crypto based Multi features Website",
    //   name: "Various kinds of Advertisement Options",
    // },
    // { title: "Crypto based Multi features Website", name: "Notification" },
    // { title: "Crypto based Multi features Website", name: "Theme Options" },
    // { title: "Crypto based Multi features Website", name: "Layout Options" },
    // {
    //   title: "Crypto based Multi features Website",
    //   name: "Tooltips and Modal Windows",
    // },
    {
      title: "API based Website",
      pipe: "|",
      name: "Blockchain wallet connection ",
    },
    { title: "API based Website", pipe: "|", name: "Fetching through API " },
    { title: "API based Website", pipe: "|", name: "Membership " },
    {
      title: "API based Website",
      pipe: "|",
      name: "Third Party Authentication ",
    },
    { title: "API based Website", pipe: "|", name: "Display Price Charts " },
    { title: "API based Website", pipe: "|", name: "Currency Options " },
    { title: "API based Website", pipe: "|", name: "Color Theme Options " },
    { title: "API based Website", pipe: "|", name: "Set as favourite " },
    {
      title: "API based Website",
      pipe: "|",
      name: "Donate in Cryptocurrency ",
    },
    { title: "API based Website", name: "Analysis on Fetched Data " },
    { title: "Blog", pipe: "|", name: "Classification by Categories " },
    {
      title: "Blog",
      pipe: "|",
      name: "Related Posts depending upon Category ",
    },
    { title: "Blog", pipe: "|", name: "Recent Posts " },
    { title: "Blog", pipe: "|", name: "Featured/ Sponsored Posts " },
    { title: "Blog", pipe: "|", name: "Banner Advertisement Option " },
    { title: "Blog", pipe: "|", name: "Featured Image Option " },
    { title: "Blog", pipe: "|", name: "About Author " },
    { title: "Blog", name: "Post Comments feature " },
    { title: "Landing Page", pipe: "|", name: "Anchored Menu " },
    { title: "Landing Page", pipe: "|", name: "Flippable Card " },
    {
      title: "Landing Page",
      pipe: "|",
      name: "Faded Background with Content on Top ",
    },
    { title: "Landing Page", pipe: "|", name: "Skewed/ Clipped Backgrounds " },
    {
      title: "Landing Page",
      name: "Stacked Images that stand out on Hover ",
    },
    {
      title: "Social Media App",
      pipe: "|",
      name: "Create Account with third party APIs ",
    },
    {
      title: "Social Media App",
      pipe: "|",
      name: "Creating Unique Communities ",
    },
    { title: "Social Media App", pipe: "|", name: "Comment and Vote " },
    {
      title: "Social Media App",
      pipe: "|",
      name: "Create Posts with Title, Message and Images and/ or delete it ",
    },
    {
      title: "Social Media App",
      name: "Top Communities Suggestions ",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="container mx-auto md:py-10 py-6 lg:px-5 px-2 text-xl font-Roboto">
      <div className="grid grid-cols-1 lg:grid-cols-12    ">
        {/* First Column */}
        <div className="  lg:col-span-3  flex flex-col pb-5">
          <h2 className="md:text-3xl sm:text-2xl   font-semibold  text-center pb-5">
            Project Examples
          </h2>
          {/* justify-start py-1 md:px-16 px-5 */}
          <div className="grid grid-cols-1 xs:grid-cols-12    ">
            {myProjects.map((project, index) => (
              <div
                key={index}
                className="lg:col-span-12 col-span-6 flex  justify-start sm:py-1   md:px-16 px-2"
              >
                {currentIndex === index ? (
                  <p className=" md:text-xl text-lg bg-white p-1 cursor-pointer ">
                    {project.title}
                  </p>
                ) : (
                  <p
                    className=" md:text-xl text-lg   cursor-pointer  lefthover "
                    onClick={() => handleClick(index)}
                  >
                    {project.title}
                    <span className="absolute left-0 -bottom-1 w-full h-2 bg-white -z-10 project-hover"></span>
                  </p>
                )}
                {/* hover:border-b-4 border-white  */}
              </div>
            ))}
          </div>
        </div>

        {/* Second column */}
        <div className=" lg:col-span-6  md:px-10 px-2 md:space-y-0 sm:space-y-5  pb-5">
          {/* Name and Purpose */}
          <div className="md:h-32 h-36 xs:overflow-hidden overflow-scroll mb-3">
            <div>
              <h2 className="md:text-2xl  font-semibold  text-themeDarkPurple  pb-2">
                <a
                  href={myProjects[currentIndex].url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {myProjects[currentIndex].name}
                </a>
              </h2>
            </div>
            <div>
              <p className="purpose font-semibold text-gray-500  ">
                {myProjects[currentIndex].purpose}
              </p>
            </div>
          </div>
          {/* Image */}
          <div className=" border-4 border-themeDarkPurple ">
            <img
              src={myProjects[currentIndex].image}
              alt={myProjects[currentIndex].name}
              className=" h-full w-full"
            />
          </div>
          {/* Prev And Next Btn */}
          <div className="flex justify-center items-center gap-10">
            <div
              className=" flex justify-center items-center "
              onClick={() =>
                handleClick(
                  currentIndex === 0 ? myProjects.length - 1 : currentIndex - 1
                )
              }
            >
              <p className=" cursor-pointer font-bold text-6xl text-gray-500 ">
                &laquo;
              </p>
            </div>
            <div
              className="flex justify-center items-center"
              onClick={() =>
                handleClick(
                  currentIndex === myProjects.length - 1 ? 0 : currentIndex + 1
                )
              }
            >
              <p className=" cursor-pointer font-bold text-6xl text-gray-500">
                &raquo;
              </p>
            </div>
          </div>
        </div>
        {/* Third column */}
        <div className=" lg:col-span-3  tools_bg  lg:px-5 md:px-10  px-2 md:space-y-0 space-y-5 ">
          {/* Features */}
          <div className="lg:h-auto md:h-36 h-40 xs:overflow-hidden overflow-scroll ">
            <h2 className="md:text-2xl   font-semibold ">Features</h2>

            <div className="  ">
              {features.map((feature, index, arr) => (
                <div
                  key={index}
                  className={`${
                    feature.title === myProjects[currentIndex].title
                      ? "inline"
                      : "hidden"
                  }`}
                >
                  {feature.title === myProjects[currentIndex].title && (
                    <span className=" features font-semibold text-gray-500 ">
                      {feature.name}
                      <span className="font-bold text-2xl text-blue-500">
                        {feature.pipe}
                      </span>
                      &nbsp;&nbsp;
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Tools and Tech */}
          <div className="md:h-48 h-40">
            <h2 className="md:text-2xl font-semibold pt-2 pb-2">
              Tools and Technologies used
            </h2>

            <div className="grid grid-cols-2 lg:grid-cols-12  ">
              {tools.map((tool, index) => (
                <div
                  key={index}
                  className={`${
                    tool.title === myProjects[currentIndex].title
                      ? "lg:col-span-6 flex justify-start items-start py-1 "
                      : "hidden"
                  }`}
                >
                  {tool.title === myProjects[currentIndex].title && (
                    <p className=" text-sm font-semibold text-gray-500 border-l-[3px]  border-gray-400 px-1  shadow-xl p-1">
                      {tool.name}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Sharing Link */}
          <div className="flex justify-center items-center gap-10 ">
            <p className=" md:text-xl text-lg text-themeDarkPurple border-b-2 border-themeDarkPurple hover:border-b-0 centerhover">
              <a
                href={myProjects[currentIndex].url}
                target="_blank"
                rel="noreferrer"
              >
                Website
              </a>
            </p>

            <p className="md:text-xl text-lg  text-themeDarkPurple border-b-2 border-themeDarkPurple hover:border-b-0 centerhover">
              <a
                href={myProjects[currentIndex].codeurl}
                target="_blank"
                rel="noreferrer"
              >
                Code
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Projects), "Projects", "project__bg");
