import React from "react";
import AppWrap from "./wrapper/AppWrap";
import MotionWrap from "./wrapper/MotionWrap";
import shubhra from "../assets/shubhra.jpg";

const Hireme = () => {
  return (
    <div className="container mx-auto md:py-10 py-6 lg:px-5 px-8 text-xl font-Roboto">
      <div className="sm:space-y-0 space-y-5 md:ml-20 sm:flex lg:gap-20 gap-5  p-4 items-center justify-center text-center  relative rounded-lg bg-blue-200 border-y-4  border-white shadow-2xl">
        <div className="flex flex-col justify-center items-center rounded-full  ">
          <img
            alt={shubhra}
            height="150px"
            width="150px"
            className="align-middle rounded-full"
            src={shubhra}
          />
        </div>
        <div className="space-y-10">
          <p className="text-3xl md:mx-5 mx-15 md:text-left  text-center">
            Nudge me to build your next website!
          </p>
          <button className=" hiremebtn font-bold  ">
            <a href="#Contact">CLICK</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Hireme), "Hireme", "hireme__bg");
