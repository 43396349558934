import React, { useState } from "react";
import AppWrap from "./wrapper/AppWrap";
import MotionWrap from "./wrapper/MotionWrap";

const Skills = () => {
  const buildSkills = [
    {
      title: "Clients' Imaginations",
      description:
        "Because that is why I have dedicated a major portion of my life to Web Development.",
      index: 1,
    },
    {
      title: "Landing Pages",
      description:
        "Whether single or multiple, whether just an Info page or a Sales page, users need a place to land on.",
      index: 2,
    },
    {
      title: "Chat Apps",
      description: "Because speed in communication brings faster results.",
      index: 3,
    },
    {
      title: "Ecommerce Websites",
      description:
        "Because millions of people buy stuffs through Internet all the time. Internet never sleeps nor should a business.",
      index: 4,
    },
    {
      title: "Social Media Sites",
      description:
        " Because every niche or topic can have a dedicated social media platform.",
      index: 5,
    },
    {
      title: "API based Websites",
      description:
        "Because data is the source of information. Info leads to analysis and results.",
      index: 6,
    },

    {
      title: " Blogs",
      description:
        "Because 'writing' is deeply satisfying for some people and also is great for SEO.",
      index: 7,
    },
  ];

  const techSkills = [
    {
      title: "React.js",
    },
    {
      title: "Redux",
    },
    {
      title: "Next.js",
    },
    {
      title: "Node.js",
    },
    {
      title: "Express.js",
    },
    {
      title: "MongoDB",
    },
    {
      title: "Firebase",
    },
    {
      title: "Supabase",
    },
    {
      title: "GraphQL",
    },
    {
      title: "Tailwind CSS",
    },
    {
      title: "Material UI",
    },
    {
      title: "Bootstrap",
    },
    {
      title: "WordPress",
    },
    {
      title: "HTML5",
    },
    {
      title: "CSS3",
    },
    {
      title: "SASS",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="container mx-auto md:py-10 py-0 md:px-5 px-2  font-Roboto">
      <div className="grid grid-cols-1 lg:grid-cols-12  py-12 bg-white">
        {/* col 1 */}
        <div className="  lg:col-span-7  build_bg  ">
          <h2 className="md:text-3xl sm:text-2xl text-xl  font-semibold font-Roboto text-center py-5">
            I build
          </h2>
          {/* build skills */}
          <div className="grid grid-cols-2  sm:grid-cols-12 ">
            {buildSkills.map((skill, index) => (
              <div
                key={index}
                className="  sm:col-span-4  flex justify-start  py-1"
              >
                {currentIndex === index ? (
                  <p className="px-1 p-1  border-l-[6px] border-blue-300  bg-blue-200 buildTitles cursor-pointer">
                    {skill.title}
                  </p>
                ) : (
                  <p
                    className="px-1 p-1 border-l-[6px]  border-purple-300 buildTitles cursor-pointer"
                    onClick={() => handleClick(index)}
                  >
                    {skill.title}
                  </p>
                )}
              </div>
            ))}
          </div>
          {/* build card */}
          <div className="md:grid grid-cols-1 md:grid-cols-12 md:pt-12 pt-8 flex flex-col ">
            <div className="  md:col-span-8 flex justify-center  ">
              <div className="  flex flex-col justify-center  items-center  ">
                <div className="build_card md:h-64 h-64  md:w-52 xs:w-60 w-52 p-2.5">
                  <div className="buildImage_bg flex justify-center items-center">
                    <p className="md:text-xl sm:text-xl text-lg font-bold px-2 ">
                      {buildSkills[currentIndex].title}
                    </p>
                  </div>
                  <p className="text-center py-3 ">
                    {buildSkills[currentIndex].description}
                  </p>
                </div>
              </div>
            </div>
            <div className="  md:col-span-4 flex md:justify-start justify-center ">
              <div className="flex justify-center items-center gap-10">
                <div
                  className=" flex justify-center items-center "
                  onClick={() =>
                    handleClick(
                      currentIndex === 0
                        ? buildSkills.length - 1
                        : currentIndex - 1
                    )
                  }
                >
                  <p className=" cursor-pointer font-bold text-6xl text-gray-500 ">
                    &laquo;
                  </p>
                </div>
                <div
                  className="flex justify-center items-center"
                  onClick={() =>
                    handleClick(
                      currentIndex === buildSkills.length - 1
                        ? 0
                        : currentIndex + 1
                    )
                  }
                >
                  <p className=" cursor-pointer font-bold text-6xl text-gray-500">
                    &raquo;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* col 2 */}
        <div className=" lg:col-span-5  ">
          <h2 className="md:text-3xl sm:text-2xl text-xl  font-semibold font-Roboto text-center py-5 ">
            Using technologies like
          </h2>
          {/* {technologies} */}
          <div className="grid grid-cols-3 sm:grid-cols-12 ">
            {techSkills.map((skill, index) => (
              <div
                key={index}
                className="  sm:col-span-4  flex justify-start py-1 "
              >
                <p className="text-md border-l-[3px]  border-gray-400 px-1  shadow-xl p-1">
                  {skill.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Skills), "Skills", "skill__bg");
