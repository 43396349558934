/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from "react";

const NavigationDots = ({ active }) => (
  <div className="md:flex justify-center items-center flex-col p-4 hidden">
    {["Home", "Skills", "Hireme", "Projects", "Testimonials", "Contact"].map(
      (item, index) => (
        <a
          href={`#${item}`}
          key={item + index}
          className="app__navigation-dot w-2.5 h-2.5 rounded-full bg-themeDarkPurple m-2 hover:bg-themeDarkBlue"
          style={
            active === item ? { backgroundColor: "hsl(210, 100%, 65%)" } : {}
          }
        />
      )
    )}
  </div>
);

export default NavigationDots;
