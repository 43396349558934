import React from "react";

const Footer = () => {
  return (
    <div className=" w-full md:py-10 py-6  font-Roboto flex justify-center items-center app_header  ">
      <p className="text-gray-600 text-sm">
        Copyright &copy; {new Date().getFullYear()}
        {/* <a href="/">
          <span>Shubhra</span>
        </a> */}
        . All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
