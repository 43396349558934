import React from 'react';
import logo from '../assets/logo3.png';

const Header = () => {
  return (
    <div className="relative">
      <div className="app_header w-full py-6   px-4 lg:px-10  sticky top-0 z-50 backdrop-blur-sm ">
        <div className="flex justify-between items-center ">
          <div className="flex justify-start items-center ">
            <a className="cursor-pointer" href={`#Home`}>
              <img src={logo} alt="logo" height="50px" width="120px" />
            </a>
          </div>
          <div className="flex-1"></div>
          <div className="pr-8">
            <a
              className="text-xl font-bold  hover:text-themeDarkPurple"
              href="https://blog.shubhra.dev/"
            >
              BLOG
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
