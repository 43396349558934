import React from "react";
import AppWrap from "./wrapper/AppWrap";
import MotionWrap from "./wrapper/MotionWrap";
import hashtagcryptoinfo from "../assets/hashtagcryptoinfo.png";

const Testimonials = () => {
  return (
    <div className="container mx-auto md:py-10 py-6 lg:px-5 px-4 text-xl font-Roboto ">
      <div className=" md:px-10 sm:px-4 px-0 lg:flex gap-20 lg:my-8   items-center  text-center  relative rounded-lg border-4 border-white">
        <div className="flex flex-col justify-center items-center lg:py-20  py-4">
          <img
            alt={hashtagcryptoinfo}
            height="50px"
            width="50px"
            className="align-middle rounded-full"
            src={hashtagcryptoinfo}
          />

          <h3 className="text-gray-600 text-lg font-bold sm:border-b-0 border-b-2 border-purple-300">
            hashtagcryptoinfo
          </h3>
        </div>
        <p className="text-md mx-5 text-left lg:pb-0  pb-5">
          She displayed qualities like ownership and creativity that resulted in
          a website better than we thought. She put in extra hours and worked
          overtime when we required her to. What we noticed is that she strives
          hard to give the best quality in whatever we ask her to do for her own
          job and creativity satisfaction. This turned out in our best interest
          as well. It felt easy, friendly and pleasant to work with her. We can
          say that our search for a reliable and trustworthy web developer now
          ends. She will be our first preference for future requirements.
        </p>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Testimonials),
  "Testimonials",
  "testimonial__bg"
);
